import React from "react"
import SbEditable from "storyblok-react"
import { render } from "storyblok-rich-text-react-renderer"
import Link from "gatsby-link"
import Fade from 'react-reveal/Fade'

const LogoImage = ({ blok }) => {
  return (
    <SbEditable content={blok} key={blok._uid}>
      <div class="pt-6 group">
        <div class="flow-root h-4/5 bg-gray-50">
          <Fade distance="20px" bottom>
            <div>
            <Link
              to={`/${blok.button_link.cached_url}`}
              prefetch="true"
              target={blok.button_link.linktype === "url" && "_blank"}
            >
              <img
                className="w-full"
                src={blok.image.filename}
                alt={blok.image.alt}
              />
              </Link>
            </div>
          </Fade>
          <div class="mt-6 px-6">
            <Fade distance="20px" bottom>
              <div>
                <h3 class="mt-8 text-lg font-extrabold text-gray-700 tracking-tight uppercase">
                  {blok.title}
                </h3>
                <p class="mt-5 text-base text-gray-300">{render(blok.content)}</p>
              </div>
            </Fade>
          </div>
        </div>
        <div className="bg-gray-50 px-6 pb-8">
          <Fade distance="20px" bottom>
            <Link
              to={`/${blok.button_link.cached_url}`}
              prefetch="true"
              className={`mt-8 w-11/12 inline-flex items-center justify-center px-12 py-3 border border-transparent text-base font-extrabold bg-yellow-500 text-white hover:bg-gray-700 uppercase place-self-end}`}
              target={blok.button_link.linktype === "url" && "_blank"}
            >
              Find out more
            </Link>
          </Fade>
        </div>
      </div>
    </SbEditable>
  )
}

export default LogoImage
