/*

Product Gallery

*/

import React from "react"
import SbEditable from "storyblok-react"
import DynamicComponent from "./DynamicComponent"
import Fade from 'react-reveal/Fade'

const ProductGallery = ({ blok }) => {
  return (
    <SbEditable content={blok} key={blok._uid}>
      <div>
        <div className="mx-auto max-w-md px-4 py-16 lg:py-24 text-center sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl">
          <div className="mb-10 lg:mb-24">
            <Fade distance="20px" bottom>
              <h2 className="block text-4xl text-center text-gray-700 font-semibold tracking-tight uppercase">
                {blok.title_line_1}
              </h2>
              <h2 className="block text-4xl text-center text-gray-700 font-extrabold tracking-tight uppercase mb-3">
                {blok.title_line_2}
              </h2>
              <p className="block text-base text-sans text-gray-500">
                {blok.description}
              </p>
            </Fade>
          </div>
          <div className="columns-1 md:columns-3">
            {blok.gallery_items.map(childBlok => (
              <DynamicComponent blok={childBlok} key={childBlok._uid} />
            ))}
          </div>
        </div>
      </div>
    </SbEditable>
  )
}

export default ProductGallery
