/*

Split Two Tone

https://tailwindui.com/components/marketing/sections/contact-sections#component-2a08a861356f15a3d7b56550410eb175

*/

import React from "react"
import SbEditable from "storyblok-react"
import { render } from "storyblok-rich-text-react-renderer"
import Fade from 'react-reveal/Fade';

const Hero = ({ blok }) => {
  return (
    <SbEditable content={blok} key={blok._uid}>
      <div className="relative">
        <div className="absolute inset-x-0 bottom-0 h-1/2 bg-gray-100" />
        <div className="w-full">
          <div className="relative shadow-xl sm:overflow-hidden">
            <div className="absolute inset-0">
              <img
                className="h-full w-full object-cover"
                src={blok.background_image}
                alt={blok.image_description}
              />
              <div className="absolute inset-0 bg-gray-400 mix-blend-multiply"></div>
            </div>
            <div className="relative px-4 py-16 sm:px-6 sm:py-24 lg:py-48 lg:px-8 max-w-6xl mx-auto">
              <Fade distance="20px">
                <h1 className="text-center text-4xl text-white tracking-tight sm:text-5xl lg:text-6xl uppercase">
                  {render(blok.text_overlay)}
                </h1>
              </Fade>
            </div>
          </div>
        </div>
      </div>
    </SbEditable>
  )
}

export default Hero
