/*

Product Gallery Item

*/

import React from "react"
import SbEditable from "storyblok-react"
import Fade from 'react-reveal/Fade'

const ProductGalleryItem = ({ blok }) => {
  return (
    <SbEditable content={blok} key={blok._uid}>
      <Fade distance="20px" bottom cascade>
        <div className="relative group mb-4 w-full">
          <img className="w-full" src={blok.image.filename} alt={blok.image.alt} />
          <div className="absolute top-0 left-0 w-full h-full flex-col items-center justify-center px-8 bg-yellow-500 bg-opacity-95 hidden group-hover:flex">
            <h3 className="text-lg font-extrabold text-gray-700 uppercase mb-3">
              {blok.title}
            </h3>
            <p className="text-white text-base mb-4">{blok.description}</p>
          </div>
        </div>
      </Fade>
    </SbEditable>
  )
}

export default ProductGalleryItem
