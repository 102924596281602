/*

Off White Grid

https://tailwindui.com/components/marketing/sections/logo-clouds#component-e9f731fb54a4381605a5d05a8da56a2b

*/

import React from "react"
import SbEditable from "storyblok-react"
import DynamicComponent from "./DynamicComponent"
import Fade from 'react-reveal/Fade';

const OffWhiteGrid = ({ blok }) => {
  return (
    <SbEditable content={blok} key={blok._uid}>
      <div className="bg-yellow-500">
        <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
          <Fade distance="20px" bottom>
            <span className="block text-center text-base font-extrabold uppercase text-gray-700 tracking-widest uppercase">
              {blok.title}
            </span>
          </Fade>
          <div className="mt-6 grid grid-cols-2 gap-0.5 md:grid-cols-4 lg:mt-8">
            {blok.image.map(blok => (
              <DynamicComponent blok={blok} />
            ))}
          </div>
        </div>
      </div>
    </SbEditable>
  )
}

export default OffWhiteGrid
