import React from "react"
import Navigation from "./Navigation"
import Footer from "./Footer"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
//import CookieConsent from 'react-cookie-consent';

import StoryblokService from "../utils/storyblok-service"

export default function Layout({ children, location, lang, isEditor }) {
  const { settings } = useStaticQuery(graphql`
    query Settings {
      settings: allStoryblokEntry(
        filter: { field_component: { eq: "settings" } }
      ) {
        edges {
          node {
            name
            full_slug
            content
          }
        }
      }
    }
  `)
  let content = JSON.parse(settings.edges[0].node.content)
  let parsedSetting = Object.assign({}, content, { content: content })

  return (
    <div className="bg-white">
      {isEditor ? (
        <>
          <Helmet
            script={[
              {
                src: `//app.storyblok.com/f/storyblok-latest.js?t=${StoryblokService.token}`,
                type: "text/javascript",
              },
            ]}
          />
          <Helmet
            script={[
              {
                innerHTML: `var StoryblokCacheVersion = '${StoryblokService.getCacheVersion()}';`,
                type: "text/javascript",
              },
            ]}
          />
        </>
      ) : null}
      <Helmet>
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
        <link
          href="https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;800&display=swap"
          rel="stylesheet"
        />
      </Helmet>
      <div className="sticky top-0 z-50">
        <div className="w-full lg:hidden transition-all relative duration-300 overflow-hidden bg-yellow-500">
          <a
            href="tel:07551169141"
            className="text-white font-bold p-2 block text-center text-xl"
          >
          Call us
          </a>
        </div>
        <Navigation settings={parsedSetting} />
      </div>
      {/*<CookieConsent
          location="bottom"
          buttonText="Accept"
          declineButtonText="Decline"
          cookieName="gatsby-gdpr-google-analytics">
        This site uses cookies ...
      </CookieConsent> */}
      <main>{children}</main>
      <Footer />
    </div>
  )
}