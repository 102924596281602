import React from "react"
import SbEditable from "storyblok-react"
import { render } from "storyblok-rich-text-react-renderer"
import Fade from 'react-reveal/Fade';

const FaqQuestion = ({ blok }) => {
  return (
    <SbEditable content={blok} key={blok._uid}>
      <dl class="space-y-12 mb-8">
        <Fade distance="20px" bottom cascade>
          <div>
            <dt class="text-lg leading-7 font-extrabold text-gray-700 uppercase">
              {blok.question}
            </dt>
            <dd class="mt-2 text-base text-gray-500">{render(blok.answer)} </dd>
          </div>
        </Fade>
      </dl>
    </SbEditable>
  )
}

export default FaqQuestion
