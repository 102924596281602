/*

Off White Grid

https://tailwindui.com/components/marketing/sections/logo-clouds#component-e9f731fb54a4381605a5d05a8da56a2b

*/

import React from "react"
import SbEditable from "storyblok-react"
import DynamicComponent from "./DynamicComponent"
import Fade from 'react-reveal/Fade';

const GridWithImages = ({ blok }) => {
  return (
    <SbEditable content={blok} key={blok._uid}>
      <div class="relative bg-white py-16 sm:py-24 lg:py-32">
        <div class="mx-auto max-w-md px-4 text-center sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl">
          <Fade distance="20px" bottom cascade>
            <div>
              <h2 class="block text-base text-center text-yellow-500 font-extrabold tracking-wide uppercase">
                {blok.small_title}
              </h2>
              <p class="mt-2 block text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-700 sm:text-4xl uppercase">
                {blok.large_title}
              </p>
              <p class="my-5 max-w-prose mx-auto text-base text-gray-500">
                {blok.content}
              </p>
            </div>
          </Fade>
          <div class="mt-12">
            <div class="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3 grid-rows-1">
              {blok.grid_items_with_icon.map(blok => (
                <DynamicComponent blok={blok} />
              ))}
            </div>
          </div>
        </div>
      </div>
    </SbEditable>
  )
}

export default GridWithImages
