import React from "react"
import SbEditable from "storyblok-react"
import { render } from "storyblok-rich-text-react-renderer"
import Fade from 'react-reveal/Fade';

const LogoImage = ({ blok }) => {
  return (
    <SbEditable content={blok} key={blok._uid}>
      <div class="pt-6 group">
        <div class="flow-root bg-gray-50 p-8 h-full group-hover:bg-yellow-500 cursor-pointer">
          <div class="-mt-12">
            <Fade distance="20px" bottom>
              <div>
                <span class="inline-flex items-center justify-center p-3 bg-yellow-500 shadow-lg group-hover:bg-gray-700">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-6 w-6 stroke-current text-white group-hover:text-white"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M5 13l4 4L19 7"
                    />
                  </svg>
                </span>
              </div>
            </Fade>
            <Fade distance="20px" bottom cascade>
              <div>
                <h3 class="mt-8 text-lg font-extrabold text-gray-700 tracking-tight uppercase">
                  {blok.title}
                </h3>
                <p class="mt-5 text-base text-gray-300">{render(blok.content)}</p>
              </div>
            </Fade>
          </div>
        </div>
      </div>
    </SbEditable>
  )
}

export default LogoImage
