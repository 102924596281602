import React from "react"
import SbEditable from "storyblok-react"
import Fade from 'react-reveal/Fade';

const IconImage = ({ blok }) => {
  return (
    <SbEditable content={blok} key={blok._uid}>
      <div className="col-span-1 flex justify-center py-8 px-4 md:px-8 bg-none">
        <Fade distance="20px" bottom>
          <img
            className="max-h-12"
            src={blok.logo_image}
            alt={blok.logo_description}
          />
        </Fade>
      </div>
    </SbEditable>
  )
}

export default IconImage
