/*

https://tailwindui.com/components/marketing/sections/content-sections#component-3f7a35fa653498b2c62441a98df3dc08

*/

import React from "react"
import SbEditable from "storyblok-react"
import { render } from "storyblok-rich-text-react-renderer"
import Fade from 'react-reveal/Fade';

const SplitImage = ({ blok }) => {
  return (
    <SbEditable content={blok} key={blok._uid}>
      <div className="relative bg-white">
        <div className="relative md:my-12 lg:my-20 pt-12 pb-16 px-4 sm:pt-16 sm:px-6 lg:px-8 lg:max-w-7xl lg:mx-auto lg:grid lg:grid-cols-2">
          <div
            className={`lg:absolute lg:inset-0 ${
              blok.switch_layout ? "lg:col-start-2" : ""
            }`}
          >
            <div
              className={`lg:absolute lg:inset-y-0 lg:left-0 ${
                blok.switch_layout ? "lg:w-full" : "lg:w-1/2"
              }`}
            >
              <Fade distance="20px" bottom>
                <img
                  className="h-56 w-full object-cover lg:absolute lg:h-full"
                  src={blok.image}
                  alt=""
                />
              </Fade>
            </div>
          </div>
          <div
            className={`${
              blok.switch_layout
                ? "lg:pr-8 lg:col-start-1"
                : "lg:pl-8 lg:col-start-2"
            }`}
          >
            <div
              className={`text-base max-w-prose mx-auto mt-9 lg:mt-0 lg:max-w-lg ${
                blok.switch_layout ? "lg:mr-auto lg:ml-0" : "lg:ml-auto lg:mr-0"
              }`}
            >
              <Fade distance="20px" bottom cascade>
                <h2 className="leading-7 text-yellow-500 font-extrabold tracking-wide uppercase">
                  {blok.small_title}
                </h2>
                <h3 className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-700 sm:text-4xl uppercase leading-none">
                  <span className="font-semibold">
                    {blok.large_title_first_line}
                  </span>
                  <br />
                  <span className="font-extrabold">
                    {blok.large_title_second_line}
                  </span>
                </h3>
              </Fade>
              <Fade distance="20px" left>
                <div className="mt-8 text-base text-gray-500 show-bullets">
                    {render(blok.content)}
                </div>
              </Fade>
            </div>
          </div>
        </div>
      </div>
    </SbEditable>
  )
}

export default SplitImage
