import React from "react"
import Link from "gatsby-link"
import SbEditable from "storyblok-react"
import { useStaticQuery, graphql } from "gatsby"

const FooterNavigation = () => {
  const { settings } = useStaticQuery(graphql`
    query FooterSettings {
      settings: allStoryblokEntry(
        filter: { field_component: { eq: "settings" } }
      ) {
        edges {
          node {
            name
            full_slug
            content
          }
        }
      }
    }
  `)

  let content = JSON.parse(settings.edges[0].node.content)
  let parsedSetting = Object.assign({}, content, { content: content })

  return (
    <nav
      className="-mx-5 -my-2 flex flex-wrap justify-center mb-8"
      aria-label="Footer"
    >
      {parsedSetting &&
        parsedSetting.content.footer_navi.map((navitem, index) => (
          <SbEditable content={navitem} key={navitem._uid}>
            <div key={index} className="px-5 py-2">
              <Link
                to={`/${navitem.link.cached_url.replace("home", "")}`}
                prefetch="true"
                className="text-xs text-gray-500 hover:text-white uppercase"
              >
                {navitem.top_line}
                <br />
                {navitem.bottom_line}
              </Link>
            </div>
          </SbEditable>
        ))}
    </nav>
  )
}

export default FooterNavigation
