/*

Simple Centered Branded

https://tailwindui.com/components/marketing/sections/cta-sections#component-1db4588d58587eda7cf40b260dc30833
*/

import React from "react"
import SbEditable from "storyblok-react"
import Link from "gatsby-link"
import { render } from "storyblok-rich-text-react-renderer"
import Fade from 'react-reveal/Fade'

const simpCenBranded = ({ blok }) => {
  return (
    <SbEditable content={blok} key={blok._uid}>
      <div className="bg-gray-700">
        <div className="max-w-2xl mx-auto text-center py-16 px-4 sm:py-28 sm:px-6 lg:px-8 text-white">
          <Fade distance="20px" bottom cascade>
            <div>
              <h2 class="block text-base text-center text-yellow-500 font-extrabold tracking-wide uppercase mb-3">
                {blok.small_title}
              </h2>
            </div>
            <div>
              <h2 className="text-3xl text-white sm:text-5xl mb-8">
                <span className="block uppercase">{blok.top_line_title}</span>
                <span className="block font-extrabold uppercase">
                  {blok.bottom_line_title}
                </span>
              </h2>
              <span class="block grey-block-divide">{render(blok.content)}</span>
              <Link
                to={`/${blok.link.cached_url}`}
                prefetch="true"
                className="mt-8 w-full sm:w-2/3 inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-extrabold bg-yellow-500 text-white hover:bg-yellow-900 sm:w-auto uppercase"
              >
                {blok.label}
              </Link>
            </div>
          </Fade>
        </div>
      </div>
    </SbEditable>
  )
}

export default simpCenBranded
