import Link from "gatsby-link"
import React from "react"
import FooterNavigation from "./FooterNavigation"
import Fade from 'react-reveal/Fade'

const Footer = () => {
  return (
    <footer className="bg-gray-700">
      <div className="max-w-7xl mx-auto py-12 px-4 overflow-hidden sm:px-6 lg:px-8">
        <Fade distance="20px" bottom>
          <FooterNavigation />

          <p className="text-xs text-gray-500 text-center mb-2">
            UK Fast Flow, 2 Sycamore Court, Kirkburton, West Yorkshire, HD8 0TD
          </p>

          <div className="flex flex-col sm:flex-row justify-center sm:divide-x-[1px] sm:divide-gray-500 sm:divide-solid text-center text-gray-500 items-center text-xs">
            <div className="py-2 sm:py-0 sm:px-3 text-center leading-3">
              T:{" "}
              <a
                className="text-gray-500 hover:text-white"
                href="tel:+44 (0) 755 1169 141"
              >
                +44 (0) 755 1169 141
              </a>{" "}
            </div>
            <div className="py-2 sm:py-0 sm:px-3 text-center leading-3">
              T:{" "}
              <a
                className="text-gray-500 hover:text-white"
                href="tel:+44 (0) 789 9688 622"
              >
                +44 (0) 789 9688 622
              </a>
            </div>
            <div className="py-2 sm:py-0 sm:px-3 text-center leading-3">
              E:{" "}
              <a
                className="text-gray-500 hover:text-white"
                href="mailto:ukfastflow@gmail.com"
              >
                ukfastflow@gmail.com
              </a>
            </div>
          </div>

          <div className="flex flex-row justify-center divide-x-[1px] divide-gray-500 sm:divide-solid text-center text-gray-500 items-center text-xs mt-6">
            <div className="py-2 sm:py-0 sm:px-3 text-center leading-3">
              <Link
                to="/privacy-policy"
                className="text-gray-500 hover:text-white"
                prefetch="true"
              >
                Privacy policy
              </Link>
            </div>
            <div className="py-2 sm:py-0 sm:px-3 text-center leading-3">
              <Link
                to="/cookie-policy"
                className="text-gray-500 hover:text-white"
                prefetch="true"
              >
                Cookie policy
              </Link>
            </div>
          </div>

          <div className="mt-8 flex justify-center space-x-6">
            <a
              href="https://www.facebook.com/Ukfastflow/"
              prefetch="true"
              className={`text-base text-gray-500 hover:text-white`}
              target="_blank"
            >
              <span className="sr-only">Facebook</span>
              <svg
                className="h-6 w-6"
                fill="currentColor"
                viewBox="0 0 24 24"
                aria-hidden="true"
              >
                <path
                  fill-rule="evenodd"
                  d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
                  clip-rule="evenodd"
                />
              </svg>
            </a>
          </div>
          <hr className="m-h-[1px] border-gray-500 mt-9" />

          <p className="mt-8 text-center text-gray-500 text-xs">
            &copy; {new Date().getFullYear()} Fast Flow Ltd. All rights reserved.
          </p>
        </Fade>
      </div>
    </footer>
  )
}

export default Footer
