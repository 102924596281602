/*

Centered (Used for privacy & cookies policies)

https://tailwindui.com/components/marketing/sections/content-sections#component-be88a98fa8e54342829a636bd0bff3f6

*/

import React from "react"
import SbEditable from "storyblok-react"
import { render } from "storyblok-rich-text-react-renderer"
import Fade from 'react-reveal/Fade';

const Centered = ({ blok }) => {
  return (
    <SbEditable content={blok} key={blok._uid}>
      <div className="relative py-16 sm:py-24 lg:py-28 bg-white overflow-hidden">
        <div className="relative px-4 sm:px-6 lg:px-8 w-full max-w-[664px] mx-auto">
          <div className="text-lg max-w-prose mx-auto">
            <Fade distance="20px" bottom cascade>
              <h1>
                <span className="block text-base text-center text-yellow-500 font-extrabold tracking-wide uppercase">
                  {blok.small_title}
                </span>
                <span className="mt-2 block text-3xl text-center font-semibold leading-8 tracking-tight text-gray-700 sm:text-4xl uppercase">
                  {blok.top_title}
                </span>
                <span className="block text-3xl text-center font-bold leading-8 tracking-tight text-gray-700 sm:text-4xl uppercase">
                  {blok.bottom_title}
                </span>
              </h1>
            </Fade>
          </div>
          <div class="mt-5 leading-7 text-base text-gray-500 mx-auto text-center">
            <Fade distance="20px" bottom>
              <div>
                {render(blok.content)}
              </div>
            </Fade>
          </div>
        </div>
      </div>
    </SbEditable>
  )
}

export default Centered
