import React from "react"
import Teaser from "./Teaser"
import Feature from "./Feature"
import Grid from "./Grid"
import BlogPost from "./BlogPost"
import Slide from "./Slide"
import Placeholder from "./Placeholder"
import ArticleTeaser from "./ArticleTeaser"
import FeaturedArticles from "./FeaturedArticles"
import PostsList from "./PostsList"
import Centered from "./Centered"
import SplitWithImage from "./SplitWithImage"
import OffsetSupportingText from "./OffsetSupportingText"
import FaqQuestion from "./FaqQuestion"
import OffWhiteGrid from "./OffWhiteGrid"
import LogoImage from "./LogoImage"
import GridWithOffsetIcons from "./GridWithOffsetIcons"
import GridItemIconImage from "./GridItemIconImage"
import SimpleJustifiedOnLightBrand from "./SimpleJustifiedOnLightBrand"
import SplitTwoTone from "./SplitTwoTone"
import Hero from "./Hero"
import SimpleCenteredBranded from "./SimpleCenteredBranded"
import ProductGallery from "./ProductGallery"
import ProductGalleryItem from "./ProductGalleryItem"
import GridWithImages from "./GridWithImages"
import GridItemImage from "./GridItemImage"
import GoogleMapBlock from "./GoogleMapBlock"
import CenteredWide from "./CenteredWide"
import CookieContent from "./CookieContent"

const Components = {
  "teaser": Teaser,
  "grid": Grid,
  "feature": Feature,
  "blogpost": BlogPost,
  "slide": Slide,
  "article-teaser": ArticleTeaser,
  "featured-articles": FeaturedArticles,
  "posts-list": PostsList,
  "main-content": Centered,
  "centered-wide": CenteredWide,
  "split-with-image": SplitWithImage,
  "faqs": OffsetSupportingText,
  "faq-question": FaqQuestion,
  "off-white-grid": OffWhiteGrid,
  "logo": LogoImage,
  "grid-offset-icons": GridWithOffsetIcons,
  "grid-item-icon-and-image": GridItemIconImage,
  "grid-with-image": GridWithImages,
  "grid-item-image-button": GridItemImage,
  "simple-justified-on-light-brand": SimpleJustifiedOnLightBrand,
  "split-two-tone": SplitTwoTone,
  "hero": Hero,
  "simple-centered-branded": SimpleCenteredBranded,
  "product-gallery": ProductGallery,
  "product-gallery-item": ProductGalleryItem,
  "fastflow-map": GoogleMapBlock,
  "cookie-content": CookieContent
}

const DynamicComponent = ({ blok }) => {
  if (typeof Components[blok.component] !== "undefined") {
    const Component = Components[blok.component]
    return <Component blok={blok} key={blok._uid} />
  }

  return blok.component ? <Placeholder componentName={blok.component} /> : null
}

export default DynamicComponent
