import React, { useEffect } from 'react'

const CookieContent = () => {

    useEffect(() => {
        const script = document.createElement('script');
      
        script.src = "https://consent.cookiebot.com/76fcf0cb-a99c-4d72-9b0b-df68a58cffb1/cd.js";
        script.async = true;
      
        document.getElementById('cookieContent').appendChild(script);
      
        return () => {
          document.getElementById('cookieContent').removeChild(script);
        }
      }, []);

      return (
        <div id="cookieContent" className="max-w-5xl mx-auto px-8 pb-12"></div>
      )

}

export default CookieContent