/*

Simple Justified On Light Brand

https://tailwindui.com/components/marketing/sections/cta-sections#component-866bae7689f78a3192c7ce764a291a44
*/

import React from "react"
import SbEditable from "storyblok-react"
import Link from "gatsby-link"
import Fade from 'react-reveal/Fade';

const simpJustLB = ({ blok }) => {
  return (
    <SbEditable content={blok} key={blok._uid}>
      <div className="bg-yellow-500">
        <Link
          to={`/${blok.link.cached_url}`}
          prefetch="true"
        >
          <div className="max-w-7xl mx-auto py-12 px-4 sm:px-8 lg:py-24 lg:px-8 lg:flex lg:items-center lg:justify-between">
            <Fade distance="20px" bottom cascade>
              <h2 className="text-2xl tracking-tight text-gray-900 md:text-4xl uppercase">
                <span className="block font-extrabold text-gray-700">
                  {blok.grey_title}
                </span>
                <span className="block font-semibold text-white">
                  {blok.white_title}
                </span>
              </h2>
            </Fade>
            <div className="mt-8 flex lg:mt-0 lg:flex-shrink-0">
              <div className="inline-flex rounded-md shadow">
                <Fade distance="20px" bottom>
                  <Link
                    to={`/${blok.link.cached_url}`}
                    prefetch="true"
                    className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-extrabold text-white bg-gray-700 hover:bg-gray-900 uppercase"
                  >
                    {blok.label}
                  </Link>
                </Fade>
              </div>
            </div>
          </div>
        </Link>
      </div>
    </SbEditable>
  )
}

export default simpJustLB
