/*

Offset with supporting text

https://tailwindui.com/components/marketing/sections/faq-sections#component-8017f4faee579f7ca518cdde140d4689

*/

import React from "react"
import SbEditable from "storyblok-react"
import { render } from "storyblok-rich-text-react-renderer"
import DynamicComponent from "./DynamicComponent"
import Fade from 'react-reveal/Fade';

const OffsetSupportingText = ({ blok }) => {
  return (
    <SbEditable content={blok} key={blok._uid}>
      <div class="bg-white">
        <div class="max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:py-20 lg:px-8">
          <div class="lg:grid lg:grid-cols-3 lg:gap-8">
            <div>
              <Fade distance="20px" bottom cascade>
                <div>
                  <h2 class="text-3xl font-extrabold text-gray-700 uppercase">
                    {blok.left_title}
                  </h2>
                </div>
                <div>
                  <span className="text-base mt-4 block  text-gray-300 faq-lc">
                    {render(blok.left_content)}{" "}
                  </span>
                </div>
              </Fade>
            </div>
            <div class="mt-12 lg:mt-0 lg:col-span-2">
              {blok.faqs_block.map(blok => (
                <dl key={blok._uid} class="space-y-12 mb-8">
                  <DynamicComponent blok={blok} />
                </dl>
              ))}
            </div>
          </div>
        </div>
      </div>
    </SbEditable>
  )
}

export default OffsetSupportingText
